import '../sass/app.scss'
import * as bootstrap from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {

    // Fade animation on load
    const els = document.querySelectorAll('.fade-in');
    els.forEach((el) => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    el.classList.add('fade-in-transition');
                    return;
                }
            });
        });

        observer.observe(el);
    })

    addEventListener("scroll", (e) => {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            document.getElementById("site-icon").style.width = "50px";
            document.getElementById("custom-icon").style.height = "20px";
            document.getElementById("contact-phone").style.marginTop = "-25px";
        } else {
            document.getElementById("site-icon").style.width = "75px";
            document.getElementById("custom-icon").style.height = "25px";
            document.getElementById("contact-phone").style.marginTop = "0";
        }
    });

    $('#ajax-contact-form').submit(function (e) {
        e.preventDefault()
    
        const last_name = $("#last_name").val();
        const first_name = $("#first_name").val();
        const email = $("#email").val();
        const phone = $("#phone").val();
        const message = $("#message").val();
        
        const ajaxurl = $(this).attr('action');

        $.ajax({
            data: { action: 'contact_form', last_name, first_name, email, phone, message },
            type: 'post',
            url: ajaxurl,
            success: function (data) {
                console.log(data);
                $(".form-success").show()
            }
        });

    });

});
